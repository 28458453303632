import { Modal } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import PrintPdf from "../components/printPdf";

interface PatientLabelModalInterface {
  open: boolean;
  closeModal: () => void;
  printRxLabelsResponse: any;
}

const FillStationPatientLabelModal: React.FC<PatientLabelModalInterface> = ({
  open,
  closeModal,
  printRxLabelsResponse,
}) => {
  const [printNow, setPrintNow] = useState(false);

  const handlePrint = async () => {
    await setPrintNow(true);
    closeModal();
  };

  return (
    <Modal open={open}>
      <div
        className="modal cus-modal fade show"
        id="printPatientModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal500 modal-dialog-centered modal-dialog-scrollable">
          <PrintPdf
            printRxLabelsResponse={printRxLabelsResponse}
            labelType="patient"
            printNow={printNow}
          />
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Print Patient Label
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              >
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.00042 3.17559L6.88792 0.288086L7.71275 1.11292L4.82525 4.00042L7.71275 6.88792L6.88792 7.71275L4.00042 4.82525L1.11292 7.71275L0.288086 6.88792L3.17559 4.00042L0.288086 1.11292L1.11292 0.288086L4.00042 3.17559Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
            <div className="modal-body print-data-body">
              <div className="s-list-data">
                <ul>
                  <li>
                    <div className="col-sm-6">
                      <label>Pharmacy Name</label>
                    </div>
                    <div className="col-sm-6">
                      <h5>
                        <div>NuFACTOR Inc.</div>
                        <div>1601 Old Greensboro</div>
                        <div>Kernersville, NC 27284-6855 / {printRxLabelsResponse?.data?.response?.rxLabel?.product_id === 'YCANTH' ? '800-315-0155' : '800-323-6832'}</div>
                      </h5>
                    </div>
                  </li>
                  <li>
                    <div className="col-sm-6">
                      <label>Prescription No. (Rx No.)</label>
                    </div>
                    <div className="col-sm-6">
                      <h5>
                        {printRxLabelsResponse?.data?.response?.rxLabel?.rx_number}
                      </h5>
                    </div>
                  </li>
                  <li>
                    <div className="col-sm-6">
                      <label>Patient First & Last Name</label>
                    </div>
                    <div className="col-sm-6">
                      <h5>
                        {
                          printRxLabelsResponse?.data?.response?.rxLabel
                            ?.patient_name
                        }
                      </h5>
                    </div>
                  </li>
                  <li>
                    <div className="col-sm-6">
                      <label>Drug Name</label>
                    </div>
                    <div className="col-sm-6">
                      <h5>
                        {
                          printRxLabelsResponse?.data?.response?.rxLabel
                            ?.rx_drugname
                        }
                      </h5>
                    </div>
                  </li>
                  <li>
                    <div className="col-sm-6">
                      <label>User Instructions</label>
                    </div>
                    <div className="col-sm-6">
                      <h5>
                        {
                          printRxLabelsResponse?.data?.response?.rxLabel
                            ?.rx_use_instructions
                        }
                      </h5>
                    </div>
                  </li>
                  <li>
                    <div className="col-sm-6">
                      <label>Manufacturer Name</label>
                    </div>
                    <div className="col-sm-6">
                      <h5>
                        {
                          printRxLabelsResponse?.data?.response?.rxLabel
                            ?.rx_manufacturer
                        }
                      </h5>
                    </div>
                  </li>
                  <li>
                    <div className="col-sm-6">
                      <label>Refills</label>
                    </div>
                    <div className="col-sm-6">
                      <h5>
                        {
                          printRxLabelsResponse?.data?.response?.rxLabel
                            ?.rx_refill_number || 0
                        }
                      </h5>
                    </div>
                  </li>
                  <li>
                    <div className="col-sm-6">
                      <label>Product Description</label>
                    </div>
                    <div className="col-sm-6">
                      <h5>
                        {
                          printRxLabelsResponse?.data?.response?.rxLabel
                            ?.rx_productdescription
                        }
                      </h5>
                    </div>
                  </li>
                  <li>
                    <div className="col-sm-6">
                      <label>Storage Type</label>
                    </div>
                    <div className="col-sm-6">
                      <h5>
                        {
                          printRxLabelsResponse?.data?.response?.rxLabel
                            ?.storage_description
                        }
                      </h5>
                    </div>
                  </li>
                  <li>
                    <div className="col-sm-6">
                      <label>Discard After</label>
                    </div>
                    <div className="col-sm-6">
                      <h5>
                        {
                          printRxLabelsResponse?.data?.response?.rxLabel
                            ?.discard_after
                        }
                      </h5>
                    </div>
                  </li>
                  <li>
                    <div className="col-sm-6">
                      <label>User First Name Intitial & Last Name</label>
                    </div>
                    <div className="col-sm-6">
                      <h5>
                        {printRxLabelsResponse?.data?.response?.rxLabel?.rph}
                      </h5>
                    </div>
                  </li>
                  <li>
                    <div className="col-sm-6">
                      <label>Total Rx Quantity</label>
                    </div>
                    <div className="col-sm-6">
                      <h5>
                        {
                          printRxLabelsResponse?.data?.response?.rxLabel
                            ?.quantity
                        }
                      </h5>
                    </div>
                  </li>
                  <li>
                    <div className="col-sm-6">
                      <label>Provider Name</label>
                    </div>
                    <div className="col-sm-6">
                      <h5>
                        {
                          printRxLabelsResponse?.data?.response?.rxLabel
                            ?.rx_provider_name
                        }
                      </h5>
                    </div>
                  </li>
                  <li>
                    <div className="col-sm-6">
                      <label>Fill Date</label>
                    </div>
                    <div className="col-sm-6">
                      <h5>
                        {printRxLabelsResponse?.data?.response?.rxLabel
                          ?.rx_fill_date &&
                          moment(
                            printRxLabelsResponse?.data?.response?.rxLabel
                              ?.rx_fill_date?.slice(0, -1)
                          ).format("MM/DD/YYYY")}
                      </h5>
                    </div>
                  </li>
                  <li>
                    <div className="col-sm-6">
                      <label>Doses Remaining Barcode Label</label>
                    </div>
                    <div className="col-sm-6">
                      <div className="barcodeImageContainerModal">
                        <img
                          className="barcodeImageModal"
                          src={
                            printRxLabelsResponse?.data?.response?.rxLabel
                              ?.base64BarCode
                          }
                          alt=""
                          title=""
                        />
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="col-sm-6">
                      <label>Print Copies</label>
                    </div>
                    <div className="col-sm-6">
                      <h5>
                        {
                          printRxLabelsResponse?.data?.response?.rxLabel
                            ?.quantity
                        }
                      </h5>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="text-end mr-t30">
                <button
                  type="button"
                  className="blue-btn pd-l10 pd-r10"
                  onClick={() => handlePrint()}
                >
                  Print Patient Label
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FillStationPatientLabelModal;
