import { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import FillStationTopLabelModal from "../../../components/fillStationTopLabelModal";
import FillStationPatientLabelModal from "../../../components/fillStationPatientLabelModal";
import AlertModal from "../../../components/alertModal";
import TopLabelMissingModal from "../../../components/topLabelMissingModal";
import LpnMissingModal from "../../../components/lpnMissingModal";
import { Link } from "react-router-dom";
import ErrorCorrectionActions from "../../../redux/fill_process/error_correction/actions";
import FillStationActions from "../../../redux/fill_process/fill_station/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { SimpleBackdropSpinner } from "../../../components/loaders";
import { Close, Edit, Save } from "@mui/icons-material";
import moment from "moment";
import qs from "qs";
import BarcodeReader from "react-barcode-reader";
const { getProductBarcodeData } = require("../../../common/helperFunctions");

const {
  initErrorCorrection,
  getOtherDetails,
  addOtherDetails,
  updateOtherDetails,
  clearUpdatedDetails,
  clearAddedDetails,
  manuallyAssociate,
  clearManuallyAssociateResponse,
  clearInitErrorCorrectionData,
  clearOtherDetails,
} = ErrorCorrectionActions;
const { getFillStationDetailsById, clearFillStationDetailsById } =
  FillStationActions;

const ErrorCorrectionStation = () => {
  const dispatch = useDispatch();

  const initErrorCorrectionData = useSelector(
    (state: any) => state?.errorCorrection?.initErrorCorrectionData
  );
  const otherDetails = useSelector(
    (state: any) => state?.errorCorrection?.otherDetails
  );
  const fillStationDetailsById = useSelector(
    (state: any) => state?.fillStation?.fillStationDetailsById
  );
  const updatedDetails = useSelector(
    (state: any) => state?.errorCorrection?.updatedDetails
  );
  const addedDetails = useSelector(
    (state: any) => state?.errorCorrection?.addedDetails
  );
  const manuallyAssociateResponse = useSelector(
    (state: any) => state?.errorCorrection?.manuallyAssociateResponse
  );

  const [processStarted, setProcessStarted] = useState<boolean>(false);
  const [topLabelModalFlag, setTopLabelModalFlag] = useState<boolean>(false);
  const [patientLabelModalFlag, setPatientLabelModalFlag] =
    useState<boolean>(false);
  const [alertModalFlag, setAlertModalFlag] = useState<boolean>(false);
  const [topLabelMissingModalFlag, setTopLabelMissingModalFlag] =
    useState<boolean>(false);
  const [lpnMissingModalFlag, setLpnMissingModalFlag] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [editIndex, setEditIndex] = useState<number>(-1);
  const [editElementId, setEditElementId] = useState("");
  const [editedLot, setEditedLot] = useState("");
  const [editedExpiration, setEditedExpiration] = useState("");
  const [editedSerialNumber, setEditedSerialNumber] = useState("");
  const [topLabelRx, setTopLabelRx] = useState("");
  const [lpnNumber, setLpnNumber] = useState("");
  const [isTopLabelScanned, setIsTopLabelScanned] = useState<boolean>(false);
  const [isTopLabelAndLpnScanned, setIsTopLabelAndLpnScanned] =
    useState<boolean>(false);
  const [isManuallyAssociatedClicked, setIsManuallyAssociatedClicked] =
    useState<boolean>(true);
  const [isTopLabelScannedForManual, setIsTopLabelScannedForManual] =
    useState<boolean>(false);
  const [topLabelAccidentalScanned, setTopLabelAccidentalScanned] =
    useState("");

  useEffect(() => {
    return () => {
      dispatch(clearFillStationDetailsById());
      dispatch(clearInitErrorCorrectionData());
      dispatch(clearManuallyAssociateResponse());
      dispatch(clearOtherDetails());
    };
  }, [dispatch]);

  useEffect(() => {
    if (manuallyAssociateResponse) {
      if (!manuallyAssociateResponse?.success) {
        toast.error(manuallyAssociateResponse?.message);
        dispatch(clearManuallyAssociateResponse());
      } else if (manuallyAssociateResponse?.success === true) {
        setIsTopLabelScannedForManual(false);
        //COPY PASTED THE RESPONSE OF INIT API MANUAL MODE BELOW
        dispatch(clearOtherDetails());
        setLoading(false);
        setProcessStarted(true);
        getOtherDetailsHandler();
        setLpnMissingModalFlag(false);
      }
    }
  }, [manuallyAssociateResponse]);

  const getOtherDetailsHandler = () => {
    const payload = {
      fill_rx_id: initErrorCorrectionData?.data?.response?.metaData?.rx_id,
    };
    dispatch(getOtherDetails(qs.stringify(payload)));
  };

  const updateMaterialDetailHandler = () => {
    if (editedSerialNumber === "") {
      toast.warn("Please enter the Serial Number");
      return;
    }
    const payload = {
      rx_dtl_id: editElementId,
      lot: editedLot,
      expiration: editedExpiration,
      serial_number: editedSerialNumber,
    };
    dispatch(updateOtherDetails(payload));
    setLoading(true);
  };

  const editItemHandler = (index: number, status: boolean) => {
    if (!status) {
      setEditIndex(-1);
      setEditElementId("");
      setEditedLot("");
      setEditedExpiration("");
      setEditedSerialNumber("");
      return;
    }
    const arr = otherDetails?.data?.response;
    setEditIndex(index);
    setEditElementId(arr[index]?.rx_dtl_id);
    setEditedLot(arr[index]?.lot);
    setEditedExpiration(
      arr[index]?.expiration &&
      moment.utc(arr[index].expiration.toLocaleString()).format("YYYY-MM-DD")
    );
    setEditedSerialNumber(arr[index]?.serial_number);
  };

  const checkValidBarcode = (code: any) => {
    var pattern = new RegExp(/^[0-9]*$/);
    return pattern.test(code);
  };

  const onScanning = async (data: any, isError: boolean) => {
    console.log("i am scanned wow", data);

    if (isManuallyAssociatedClicked) {
      let res = await fetchTopLabelDetailsForManual(data);
      if (res === 0) {
        if (isError) {
          //LPN is scanned
          if (isTopLabelScannedForManual) {
            setAlertModalFlag(false);
            const userDetails = JSON.parse(localStorage.getItem("user") || "");
            const payload2 = {
              rx_number: topLabelRx,
              lpn_number: data,
              user_id: userDetails?.id || "",
            };
            checkValidBarcode(data) && dispatch(manuallyAssociate(payload2));
          }
        } else {
          //PRODUCT IS SCANNED
          if (initErrorCorrectionData && initErrorCorrectionData?.success) {
            fetchProductLabelDetails(data);
          }
        }
      }
    } else {
      setAlertModalFlag(false);
      let res = await fetchTopLabelDetails(data);
      if (res === 0) {
        if (isError) {
          //LPN is scanned
          if (isTopLabelScanned) {
            const payload = {
              rx_number: topLabelRx,
              lpn_number: data,
            };
            setIsTopLabelAndLpnScanned(true);
            checkValidBarcode(data) &&
              dispatch(initErrorCorrection(qs.stringify(payload)));
          }
        } else {
          //PRODUCT IS SCANNED
          fetchProductLabelDetails(data);
        }
      }
    }
  };

  const fetchTopLabelDetails = (data: any) => {
    if (data?.toString()?.includes(";")) {
      if (topLabelAccidentalScanned == data.toString().split(";")[0]) {
        return 1;
      }
      setTopLabelAccidentalScanned(data.toString().split(";")[0]);
      setTopLabelRx(data.toString().split(";")[0]);
      setIsTopLabelScanned(true);
      const payload = {
        rx_number: data.toString().split(";")[0],
        lpn_number: lpnNumber,
      };
      dispatch(initErrorCorrection(qs.stringify(payload))); //dispatch the init function of error correction
      return 1;
    }
    return 0;
  };

  const fetchTopLabelDetailsForManual = (data: any) => {
    if (data?.toString()?.includes(";")) {
      setIsTopLabelScannedForManual(true);
      if (
        initErrorCorrectionData &&
        initErrorCorrectionData?.success &&
        topLabelRx != data.toString().split(";")[0]
      ) {
        setIsManuallyAssociatedClicked(false);
        setIsTopLabelScanned(true);
        dispatch(clearInitErrorCorrectionData());
        const payload = {
          rx_number: data.toString().split(";")[0],
          lpn_number: "",
        };
        dispatch(initErrorCorrection(qs.stringify(payload)));
      }
      setTopLabelRx(data.toString().split(";")[0]);
      return 1;
    }
    return 0;
  };

  const fetchProductLabelDetails = async (data: any) => {
  
    const serialized = initErrorCorrectionData?.data?.response?.metaData?.serialized;
    let { addExpiration, addLot, addSerialNumber } = await getProductBarcodeData(data, serialized);

    if (addSerialNumber) {
      const payload = {
        rx_number: initErrorCorrectionData?.data?.response?.metaData?.rx_id,
        lot: addLot || "",
        expiration: addExpiration
          ? moment.utc(addExpiration?.toLocaleString()).format("YYYY-MM-DD")
          : null,
        serial_number: addSerialNumber || "",
        gs1_label: data || "",
      };
      dispatch(addOtherDetails(payload));
      setLoading(true);
      addLot = null;
      addExpiration = null;
      addSerialNumber = null;
    } else {
      toast.error("Serial number is mandatory");
    }

  };

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("user") || "");
    const payload = {
      user_id: userDetails?.id || "",
    };
    dispatch(getFillStationDetailsById(qs.stringify(payload)));
    setLoading(true);
  }, [dispatch]);

  useEffect(() => {
    if (fillStationDetailsById) {
      setLoading(false);
      if (!fillStationDetailsById?.success) {
        toast.error(fillStationDetailsById?.message);
        dispatch(clearFillStationDetailsById());
      } else if (fillStationDetailsById?.success) {
        if (fillStationDetailsById?.data?.response?.auto) {
          setIsManuallyAssociatedClicked(false);
        } else {
          setAlertModalFlag(true);
        }
      }
    }
  }, [fillStationDetailsById]);

  useEffect(() => {
    if (initErrorCorrectionData) {
      if (!initErrorCorrectionData?.success) {
        setLoading(false);
        if (isManuallyAssociatedClicked) {
          toast.error(initErrorCorrectionData?.message); //This won't happen ever
        } else {
          if (isTopLabelAndLpnScanned) {
            setLpnMissingModalFlag(true);
            setTopLabelMissingModalFlag(false);
          } else {
            //TOP LABEL NOT FOUND IN DB
            setTopLabelMissingModalFlag(true);
            setLpnMissingModalFlag(false);
          }
        }
      } else if (initErrorCorrectionData?.success) {
        if (!isManuallyAssociatedClicked) {
          setTopLabelMissingModalFlag(false);
        }
        dispatch(clearOtherDetails());
        setLoading(false);
        setProcessStarted(true);
        getOtherDetailsHandler();
        setLpnMissingModalFlag(false);
        setIsTopLabelScanned(false);
        setIsTopLabelAndLpnScanned(false);
      }
    }
  }, [initErrorCorrectionData, dispatch]);

  useEffect(() => {
    if (otherDetails) {
      setLoading(false);
      if (!otherDetails?.success) {
        toast.error(otherDetails?.message);
      } else if (otherDetails?.success) {
      }
    }
  }, [otherDetails]);

  useEffect(() => {
    if (updatedDetails) {
      setLoading(false);
      dispatch(clearUpdatedDetails());
      if (!updatedDetails.success) {
        toast.error(updatedDetails?.message);
      } else if (updatedDetails.success) {
        getOtherDetailsHandler();
        setEditIndex(-1);
        setEditElementId("");
        setEditedLot("");
        setEditedExpiration("");
        setEditedSerialNumber("");
      }
    }
  }, [updatedDetails, dispatch]);

  useEffect(() => {
    if (addedDetails) {
      setLoading(false);
      dispatch(clearAddedDetails());
      if (!addedDetails.success) {
        toast.error(addedDetails?.message);
      } else if (addedDetails.success) {
        getOtherDetailsHandler();
      }
    }
  }, [addedDetails, dispatch]);

  const handleManuallyAssociate = () => {
    setAlertModalFlag(true);
    setIsManuallyAssociatedClicked(true);
    setIsTopLabelScanned(false);
    setTopLabelMissingModalFlag(false);
    setLpnMissingModalFlag(false);
    setIsTopLabelAndLpnScanned(false);
  };

  return (
    <section className="body-routing">
      <SimpleBackdropSpinner open={loading} />
      <div className="page-wrap">
        <div className="breadcrumb-block mode-row">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="" style={{ pointerEvents: "none" }}>
                  Fill Process
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Error Correction
              </li>
            </ol>
          </nav>
          <div className="mode-block text-center">
            <h4>Error Correction Station Mode</h4>
            <span>
              {fillStationDetailsById?.success
                ? !isManuallyAssociatedClicked
                  ? "Auto"
                  : "Manual"
                : ""}
            </span>
          </div>
        </div>
        {processStarted ? (
          <div className="error-correction-wrap">
            <div className="blue-card-panel">
              <div className="b-card-body">
                <div className="error-alert">
                  <div className="ea-icon">
                    <svg
                      width="31"
                      height="26"
                      viewBox="0 0 31 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30.381 19.5691L19.5666 2.16537C19.1293 1.50154 18.5268 0.955218 17.8147 0.576788C17.1026 0.198359 16.3037 0 15.4918 0C14.6799 0 13.881 0.198359 13.1689 0.576788C12.4568 0.955218 11.8543 1.50154 11.417 2.16537L0.602624 19.5691C0.2206 20.1871 0.0129627 20.8917 0.000587502 21.6123C-0.0117877 22.3328 0.171535 23.0438 0.532126 23.6738C0.949021 24.3829 1.55246 24.9718 2.28076 25.3805C3.00906 25.7892 3.83612 26.0029 4.67741 25.9997H26.3062C27.1419 26.0084 27.9653 25.803 28.6931 25.4042C29.4209 25.0054 30.0274 24.4274 30.4515 23.7285C30.8226 23.092 31.0118 22.3708 30.9994 21.6395C30.987 20.9082 30.7735 20.1934 30.381 19.5691ZM15.4918 20.5269C15.2129 20.5269 14.9403 20.4466 14.7085 20.2963C14.4766 20.1459 14.2959 19.9322 14.1892 19.6822C14.0824 19.4322 14.0545 19.1571 14.1089 18.8917C14.1633 18.6263 14.2976 18.3825 14.4948 18.1912C14.692 17.9998 14.9432 17.8695 15.2167 17.8167C15.4902 17.7639 15.7737 17.791 16.0314 17.8946C16.289 17.9981 16.5092 18.1735 16.6641 18.3985C16.8191 18.6235 16.9018 18.888 16.9018 19.1586C16.9018 19.5215 16.7532 19.8695 16.4888 20.1261C16.2244 20.3827 15.8657 20.5269 15.4918 20.5269ZM16.9018 15.054C16.9018 15.4169 16.7532 15.7649 16.4888 16.0215C16.2244 16.2781 15.8657 16.4222 15.4918 16.4222C15.1179 16.4222 14.7592 16.2781 14.4948 16.0215C14.2304 15.7649 14.0818 15.4169 14.0818 15.054V9.58112C14.0818 9.21824 14.2304 8.87023 14.4948 8.61364C14.7592 8.35705 15.1179 8.2129 15.4918 8.2129C15.8657 8.2129 16.2244 8.35705 16.4888 8.61364C16.7532 8.87023 16.9018 9.21824 16.9018 9.58112V15.054Z"
                        fill="#FF3939"
                      />
                    </svg>
                  </div>
                  <div className="error-con-box">
                    <h5>Fail Error Reason</h5>
                    <p>
                      {
                        initErrorCorrectionData?.data?.response?.metaData
                          ?.error_message
                      }
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="tote-card-wrap mr-t20">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-4">
                  <div className="blue-card-panel">
                    <div className="b-card-head">
                      <h5>Placement Picture of the Tote</h5>
                    </div>
                    <div className="b-card-body pd20">
                      <div className="box1">
                        <div className="box1-left">
                          <div className="tote-card1">Top Label</div>
                          <div>
                            {initErrorCorrectionData?.data?.response?.metaData
                              ?.medguide
                              ? "Med Guide"
                              : null}
                          </div>
                          <div>
                            {initErrorCorrectionData?.data?.response?.metaData
                              ?.rx_item_bag_label
                              ? "Zip Lock"
                              : null}
                          </div>
                        </div>
                        <div className="box1-right">
                          <div>
                            {
                              initErrorCorrectionData?.data?.response?.metaData
                                ?.quantity
                            }{" "}
                            {/* {
                              initErrorCorrectionData?.data?.response?.metaData
                                ?.name
                            }{" "} */}
                            {
                              initErrorCorrectionData?.data?.response?.metaData
                                ?.description
                            }
                          </div>
                          <div className="mr-l20">
                            {initErrorCorrectionData?.data?.response?.metaData
                              ?.serialized
                              ? "SCAN 2D Barcode On Each Rx Item"
                              : "SCAN 2D Barcode On Manufacturer Pack"}
                          </div>
                          {initErrorCorrectionData?.data?.response?.metaData?.supply_items?.map(
                            (item: any) => {
                              return (
                                <div>
                                  {item?.quantity} {item?.product_id}
                                </div>
                              );
                            }
                          )}
                          {otherDetails &&
                            otherDetails?.success &&
                            otherDetails?.data?.response?.map((item: any) => {
                              return (
                                <div>
                                  {item?.quantity} {item?.product_description}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-4">
                  <div className="blue-card-panel">
                    <div className="b-card-head">
                      <h5>
                        Passed Imaging Check:{" "}
                        {
                          initErrorCorrectionData?.data?.response?.metaData
                            ?.image_message
                        }
                      </h5>
                    </div>
                    <div className="b-card-body pd20">
                      <div className="singal-img-wrap">
                        <div className="col">
                          <div className="m-img text-center">
                            <img
                              src={
                                initErrorCorrectionData?.data?.response
                                  ?.metaData?.predicted_image_path
                              }
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <div className="blue-card-panel">
                    <div className="b-card-head">
                      <h5>Patient Label</h5>
                    </div>
                    <div className="b-card-body pd10">
                      <div className="pdfRxLabel1 label-box">
                        <div className="pdfRxLabelContainer">
                          <div style={{ fontWeight: "bolder" }}>
                            NuFACTOR Inc.
                          </div>
                          <div>1601 Old Greensboro</div>
                          <div>Kernersville, NC 27284-6855 / {initErrorCorrectionData?.data?.response?.rxLabel?.product_id === 'YCANTH' ? '800-315-0155' : '800-323-6832'}</div>

                          <div className="justifySpaceBetween mt-1 mb-1">
                            <div>
                              RX#{" "}
                              {
                                initErrorCorrectionData?.data?.response?.rxLabel
                                  ?.rx_number
                              }
                            </div>
                            <div>
                              {initErrorCorrectionData?.data?.response?.rxLabel
                                ?.rx_fill_date &&
                                moment(
                                  initErrorCorrectionData?.data?.response?.rxLabel?.rx_fill_date?.slice(
                                    0,
                                    -1
                                  )
                                ).format("MM/DD/YYYY")}
                            </div>
                          </div>
                          <div className="justifySpaceBetween">
                            <div></div>
                            <div>
                              {
                                initErrorCorrectionData?.data?.response?.rxLabel
                                  ?.rx_provider_name
                              }
                            </div>
                          </div>
                          <div style={{ fontWeight: "bolder" }}>
                            {
                              initErrorCorrectionData?.data?.response?.rxLabel
                                ?.patient_name
                            }
                          </div>
                          <div style={{ fontWeight: "bolder" }}>
                            {
                              initErrorCorrectionData?.data?.response?.rxLabel
                                ?.rx_drugname
                            }
                          </div>
                          <div style={{ fontWeight: "bolder" }}>
                            {
                              initErrorCorrectionData?.data?.response?.rxLabel
                                ?.rx_use_instructions
                            }
                          </div>
                          <div>
                            Mfg:{" "}
                            {
                              initErrorCorrectionData?.data?.response?.rxLabel
                                ?.rx_manufacturer
                            }
                          </div>
                          <div className="justifySpaceBetween">
                            <div>
                              Refills:{" "}
                              {initErrorCorrectionData?.data?.response?.rxLabel
                                ?.rx_refill_number || 0}
                            </div>
                            <div>
                              Qty:{" "}
                              {
                                initErrorCorrectionData?.data?.response?.rxLabel
                                  ?.quantity
                              }
                            </div>
                          </div>
                          <div>
                            {
                              initErrorCorrectionData?.data?.response?.rxLabel
                                ?.rx_productdescription
                            }
                          </div>
                          <div>
                            Storage:{" "}
                            {
                              initErrorCorrectionData?.data?.response?.rxLabel
                                ?.storage_description
                            }
                          </div>
                          <div className="justifySpaceBetween">
                            <div>
                              Discard after:{" "}
                              {initErrorCorrectionData?.data?.response?.rxLabel
                                ?.discard_after &&
                                moment(
                                  initErrorCorrectionData?.data?.response?.rxLabel?.discard_after?.slice(
                                    0,
                                    -1
                                  )
                                ).format("MM/DD/YYYY")}
                            </div>
                            <div>
                              RPh:{" "}
                              {
                                initErrorCorrectionData?.data?.response?.rxLabel
                                  ?.rph
                              }
                            </div>
                          </div>
                        </div>
                        {/* <div className="barcodeImageContainerNew">
                          <img
                            className="barcodeImageNew"
                            src={
                              initErrorCorrectionData?.data?.response?.rxLabel
                                ?.base64BarCode
                            }
                            alt="barcode"
                          />
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                {initErrorCorrectionData?.data?.response?.metaData
                  ?.product_instructions && (
                    <div className="col-sm-12">
                      <div className="note-text mr-b20">
                        <p>
                          {`Note: ${initErrorCorrectionData.data.response.metaData.product_instructions}`}
                        </p>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div className="rfm-data">
              <div className="text-end mr-b10 d-flex justify-content-end">
                {!isManuallyAssociatedClicked && (
                  <button
                    type="button"
                    className="border-blue-btn pd-l30 pd-r30"
                    data-bs-toggle="modal"
                    data-bs-target="#proceedAlertgModal"
                    onClick={() => handleManuallyAssociate()}
                  >
                    Manually Associate
                  </button>
                )}
                <button
                  type="button"
                  className="blue-btn pd-l30 pd-r30 mr-l10"
                  data-bs-toggle="modal"
                  data-bs-target="#printSModal"
                  onClick={() => setTopLabelModalFlag(true)}
                >
                  Reprint Label
                </button>
              </div>
              <div className="blue-card-panel">
                <div className="b-card-head">
                  <h5>Remaining Fill Materials</h5>
                </div>
                <div className="b-card-body table-responsive pd10">
                  <TableContainer>
                    <Table className="table small-table cus-gray-table table-center table-bordered mr0">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Seq</TableCell>
                          <TableCell align="center">Fill Product No.</TableCell>
                          <TableCell align="center">
                            Fill Product Description
                          </TableCell>
                          <TableCell align="center">Quantity</TableCell>
                          <TableCell align="center">Lot</TableCell>
                          <TableCell align="center">Expiration</TableCell>
                          <TableCell align="center">Serial No.</TableCell>
                          <TableCell align="center">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {otherDetails &&
                          otherDetails?.success &&
                          otherDetails?.data?.response?.map(
                            (item: any, index: number) => {
                              return (
                                <TableRow>
                                  <TableCell align="center">
                                    {index + 1}
                                  </TableCell>
                                  <TableCell align="center">
                                    {item.product_id}
                                  </TableCell>
                                  <TableCell align="center">
                                    {item.product_description}
                                  </TableCell>
                                  <TableCell className="text-end">
                                    {item.quantity}
                                  </TableCell>
                                  <TableCell align="center">
                                    {index === editIndex ? (
                                      <div className="t-input">
                                        <input
                                          maxLength={255}
                                          type="text"
                                          value={editedLot}
                                          name="lot"
                                          id=""
                                          onChange={(event) =>
                                            setEditedLot(event.target.value)
                                          }
                                        />
                                      </div>
                                    ) : (
                                      item.lot
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {index === editIndex ? (
                                      <div className="t-input">
                                        <input
                                          type="date"
                                          value={editedExpiration}
                                          name="expiration"
                                          id=""
                                          onChange={(event) =>
                                            setEditedExpiration(
                                              event.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    ) : (
                                      item.expiration &&
                                      moment(
                                        item.expiration?.slice(0, -1)
                                      ).format("MM/DD/YYYY")
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {index === editIndex ? (
                                      <div className="t-input">
                                        <input
                                          maxLength={255}
                                          type="text"
                                          value={editedSerialNumber}
                                          name="serialNumber"
                                          id=""
                                          onChange={(event) =>
                                            setEditedSerialNumber(
                                              event.target.value
                                            )
                                          }
                                        />
                                      </div>
                                    ) : (
                                      item.serial_number
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    {index === editIndex ? (
                                      <>
                                        <button
                                          className="action-btn"
                                          onClick={() =>
                                            editItemHandler(index, false)
                                          }
                                        >
                                          <Close fontSize="small" />
                                        </button>
                                        <button
                                          className="action-btn"
                                          onClick={() =>
                                            updateMaterialDetailHandler()
                                          }
                                        >
                                          <Save fontSize="small" />
                                        </button>
                                      </>
                                    ) : (
                                      <button
                                        className="action-btn"
                                        onClick={() => {
                                          editItemHandler(index, true);
                                        }}
                                      >
                                        <Edit fontSize="small" />
                                      </button>
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                      </TableBody>
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={3} className="text-start">
                            Total
                          </TableCell>
                          <TableCell
                            className="text-end"
                            role="button"
                            data-bs-toggle="modal"
                            data-bs-target="#qAlertgModal"
                          >
                            {
                              initErrorCorrectionData?.data?.response?.metaData
                                ?.quantity
                            }
                          </TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>
                          <TableCell align="center"></TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
            <FillStationTopLabelModal
              open={topLabelModalFlag}
              closeModal={() => setTopLabelModalFlag(false)}
              openModal={() => setPatientLabelModalFlag(true)}
              printRxLabelsResponse={initErrorCorrectionData}
            />
            <FillStationPatientLabelModal
              open={patientLabelModalFlag}
              closeModal={() => setPatientLabelModalFlag(false)}
              printRxLabelsResponse={initErrorCorrectionData}
            />
          </div>
        ) : (
          <div className="invite-card d-flex align-items-center justify-content-between">
            <h5>Please scan the Top Label to proceed with Error Tote</h5>
          </div>
        )}
        <TopLabelMissingModal
          open={topLabelMissingModalFlag}
          closeModal={() => setTopLabelMissingModalFlag(false)}
        />
        <LpnMissingModal
          open={lpnMissingModalFlag}
          closeModal={() => setLpnMissingModalFlag(false)}
        />
        <AlertModal
          open={alertModalFlag}
          closeModal={() => setAlertModalFlag(false)}
          title="Scan Tote's LPN#"
          message="Please scan the Top label and Tote's LPN# to proceed with Manually associate"
        />
      </div>
      <BarcodeReader
        onScan={(data: any) => {
          onScanning(data, false);
        }}
        onError={(error: any) => {
          onScanning(error, true);
          console.log("barcode error it is", error);
        }}
      />
    </section>
  );
};

export default ErrorCorrectionStation;