import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link } from "react-router-dom";
import Pv2FailureModal from "../../../components/pv2_failure_modal";
import SuccessModal from "../../../components/successModal";
import Pv2Actions from "../../../redux/fill_process/pv2_station/actions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AlertModal from "../../../components/alertModal";
import moment from "moment";
import { Close, Edit, Save } from "@mui/icons-material";
import { SimpleBackdropSpinner } from "../../../components/loaders";
import BarcodeReader from "react-barcode-reader";
import WorkGroupSelectionActions from "../../../redux/fill_process/work_group_selection/actions";
import qs from "qs";

const {
  initPv2Verification,
  updatePv2Verification,
  clearPv2Data,
  clearVerificationUpdateResponse,
  getFailReasons,
  updateMaterialDetails,
  getRemainingMaterialDetails,
  clearUpdateMaterialDetailResponse,
  getPv2StationStatus,
  clearPv2StationStatus,
  clearOtherMaterialsDetails,
} = Pv2Actions;

const { getWorkGroupData } = WorkGroupSelectionActions;

const Pv2Station = () => {
  const dispatch = useDispatch();

  const pv2StationData: any = useSelector(
    (state: any) => state?.pv2Station?.pv2Data
  );
  const pv2StationStatus = useSelector(
    (state: any) => state?.pv2Station?.pv2StationStatus
  );
  const pv2VerificationUpdateResponse = useSelector(
    (state: any) => state?.pv2Station?.pv2VerificationUpdateResponse
  );
  const updateMaterialDetailResponse = useSelector(
    (state: any) => state?.pv2Station?.updateMaterialDetailResponse
  );
  const failReasons = useSelector(
    (state: any) => state?.pv2Station?.failReasons
  );
  const remainingMaterials = useSelector(
    (state: any) => state?.pv2Station?.remainingMaterials
  );
  const workgroupsData = useSelector(
    (state: any) => state?.workGroupSelection?.workgroupsData
  );

  const [pv2FailureModalFlag, setPv2FailureModalFlag] =
    useState<boolean>(false);
  const [pv2SuccessModalFlag, setPv2SuccessModalFlag] =
    useState<boolean>(false);
  const [alertModalFlag, setAlertModalFlag] = useState<boolean>(true);
  const [spinnerFlag, setSpinnerFlag] = useState<boolean>(false);
  const [isAutomode, setIsAutoMode] = useState<boolean>(false);

  const [editIndex, setEditIndex] = useState<number>(-1);
  const [editElementId, setEditElementId] = useState("");
  const [editedLot, setEditedLot] = useState("");
  const [editedExpiration, setEditedExpiration] = useState("");
  const [editedSerialNumber, setEditedSerialNumber] = useState("");

  const onScanning = (data: any) => {
    //FOR TOP LABEL SCANNING
    if (data?.toString()?.includes(";")) {
      dispatch(initPv2Verification(data.toString().split(";")[0]));
      return;
    }
  };

  const editItemHandler = (index: number, status: boolean) => {
    if (!status) {
      setEditIndex(-1);
      setEditElementId("");
      setEditedLot("");
      setEditedExpiration("");
      setEditedSerialNumber("");
      return;
    }
    const arr = remainingMaterials?.data?.response;
    setEditIndex(index);
    setEditElementId(arr[index]?.rx_dtl_id);
    setEditedLot(arr[index]?.lot);
    setEditedExpiration(
      arr[index]?.expiration &&
        moment.utc(arr[index].expiration.toLocaleString()).format("YYYY-MM-DD")
    );
    setEditedSerialNumber(arr[index]?.serial_number);
  };

  const textChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "lot") {
      setEditedLot(value);
    }
    if (name === "expiration") {
      setEditedExpiration(value);
    }
    if (name === "serialNumber") {
      setEditedSerialNumber(value);
    }
  };

  const passHandler = () => {
    const userDetails = JSON.parse(localStorage.getItem("user") || "");
    const payload = {
      rxId: pv2StationData?.data?.response?.metadata?.rx_id,
      is_failed: false,
      user_id: userDetails?.id || "",
    };
    setSpinnerFlag(true);
    dispatch(updatePv2Verification(payload));
  };

  const updateMaterialDetailHandler = () => {
    if (editedSerialNumber === "") {
      toast.warn("Please enter the Serial Number");
      return;
    }
    const payload = {
      rx_dtl_id: editElementId,
      lot: editedLot,
      expiration: editedExpiration,
      serial_number: editedSerialNumber,
    };
    setSpinnerFlag(true);
    dispatch(updateMaterialDetails(payload));
  };

  const modalCloseHandler = () => {
    setPv2FailureModalFlag(false);
    setPv2SuccessModalFlag(false);
    dispatch(clearPv2Data());
    dispatch(clearVerificationUpdateResponse());
    setAlertModalFlag(true);
  };

  const getWorkGroupDataHandler = () => {
    dispatch(
      getWorkGroupData(
        pv2StationData?.data?.response?.workgroupDetails?.workgroup_id
      )
    );
  };

  useEffect(() => {
    if (pv2StationData) {
      if (!pv2StationData?.success) {
        toast.error(pv2StationData?.message);
        dispatch(clearPv2Data());
      } else if (pv2StationData?.success) {
        dispatch(
          getRemainingMaterialDetails(
            `fill_rx_id=${pv2StationData?.data?.response?.metadata?.rx_id}`
          )
        );
        getWorkGroupDataHandler();
        setAlertModalFlag(false);
      }
    }
  }, [pv2StationData, dispatch]);

  useEffect(() => {
    let timer: any;
    if (pv2StationData?.success) {
      timer = setInterval(getWorkGroupDataHandler, 20000);
    }
    return () => clearInterval(timer);
  }, [pv2StationData]);

  useEffect(() => {
    return () => {
      dispatch(clearPv2Data());
      dispatch(clearPv2StationStatus());
      dispatch(clearUpdateMaterialDetailResponse());
      dispatch(clearOtherMaterialsDetails());
      dispatch(clearVerificationUpdateResponse());
    };
  }, [dispatch]);

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("user") || "");
    const payload = {
      user_id: userDetails?.id || "",
    };
    dispatch(getFailReasons());
    dispatch(getPv2StationStatus(qs.stringify(payload)));
  }, [dispatch]);

  useEffect(() => {
    if (pv2StationStatus) {
      if (!pv2StationStatus?.success) {
        toast.error(pv2StationStatus?.message);
        dispatch(clearPv2StationStatus());
      } else if (pv2StationStatus?.success) {
        if (pv2StationStatus?.data?.response?.auto) {
          setIsAutoMode(true);
        } else if (!pv2StationStatus?.data?.response?.auto) {
          setIsAutoMode(false);
        }
      }
    }
  }, [pv2StationStatus]);

  useEffect(() => {
    if (pv2VerificationUpdateResponse) {
      setSpinnerFlag(false);
      if (!pv2VerificationUpdateResponse?.success) {
        toast.error(pv2VerificationUpdateResponse?.message);
      } else if (pv2VerificationUpdateResponse?.success) {
        // setPv2SuccessModalFlag(true);
        toast.success(pv2VerificationUpdateResponse?.message);
        setPv2FailureModalFlag(false);
        dispatch(clearPv2Data());
        dispatch(clearVerificationUpdateResponse());
        setAlertModalFlag(true);
      }
    }
  }, [pv2VerificationUpdateResponse]);

  useEffect(() => {
    if (updateMaterialDetailResponse) {
      setSpinnerFlag(false);
      if (!updateMaterialDetailResponse?.success) {
        toast.error(updateMaterialDetailResponse?.message);
      } else if (updateMaterialDetailResponse?.success) {
        toast.success(updateMaterialDetailResponse?.data?.message);
        dispatch(
          getRemainingMaterialDetails(
            `fill_rx_id=${pv2StationData?.data?.response?.metadata?.rx_id}`
          )
        );
        dispatch(clearUpdateMaterialDetailResponse());
        editItemHandler(-1, false);
        setSpinnerFlag(false);
      }
    }
  }, [updateMaterialDetailResponse, dispatch, editItemHandler]);

  return (
    <section className="body-routing">
      <SimpleBackdropSpinner open={spinnerFlag} />
      <div className="page-wrap">
        <div className="breadcrumb-block mode-row">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/fill_process/pv2_station">Fill Process</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                PV2 Station
              </li>
            </ol>
          </nav>
          <div className="mode-block text-center">
            <h4>PV2 Station Mode</h4>
            <span>
              {pv2StationStatus?.success
                ? isAutomode
                  ? "Auto"
                  : "Manual"
                : ""}
            </span>
          </div>
        </div>
        <div className="pv2-wrap">
          <div className="static-info-card mr-b20">
            <div className="blue-card-panel">
              <div className="b-card-body pd10">
                <div className="row">
                  <div className="col-sm">
                    <div className="static-info">
                      <h5>Work Group Name</h5>
                      <span>{workgroupsData?.data?.[0]?.name}</span>
                    </div>
                  </div>
                  <div className="col-sm">
                    <div className="static-info">
                      <h5>Total</h5>
                      <span>{workgroupsData?.data?.[0]?.quantity}</span>
                    </div>
                  </div>
                  <div className="col-sm">
                    <div className="static-info">
                      <h5>Remaining to Fill</h5>
                      <span>{workgroupsData?.data?.[0]?.fill_remaining}</span>
                    </div>
                  </div>
                  <div className="col-sm">
                    <div className="static-info">
                      <h5>Remaining to PV2</h5>
                      <span>{workgroupsData?.data?.[0]?.pv2_remaining}</span>
                    </div>
                  </div>
                  <div className="col-sm">
                    <div className="static-info">
                      <h5>Remaining to Pack</h5>
                      <span>{workgroupsData?.data?.[0]?.pack_remaining}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tote-card-wrap">
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-4">
                <div className="blue-card-panel">
                  <div className="b-card-head">
                    <h5>RX Item & RX Quantity in the Tote</h5>
                  </div>
                  <div>{`Rx Number: ${pv2StationData?.data?.response?.metadata?.rx_id || ''}`}</div>
                    <div>{`Patient Name: ${pv2StationData?.data?.response?.metadata?.rxPatient?.firstName || ''} ${pv2StationData?.data?.response?.metadata?.rxPatient?.lastName || ''}`}</div>
                  <div className="b-card-body pd20">
                    <div className="box1">
                      <div className="box1-left">
                        <div className="tote-card1">Top Label</div>
                        <div>
                          {pv2StationData?.data?.response?.metadata?.medguide
                            ? "Med Guide"
                            : null}
                        </div>
                        <div>
                          {pv2StationData?.data?.response?.metadata
                            ?.rx_item_bag_label
                            ? "Zip Lock"
                            : null}
                        </div>
                      </div>
                      <div className="box1-right">
                        <div>
                          {pv2StationData?.data?.response?.metadata?.quantity}{" "}
                          {/* {pv2StationData?.data?.response?.metadata?.name}{" "} */}
                          {
                            pv2StationData?.data?.response?.metadata
                              ?.description
                          }
                        </div>
                        <div className="mr-l20">
                          {pv2StationData?.data?.response?.metadata?.serialized
                            ? "SCAN 2D Barcode On Each Rx Item"
                            : "SCAN 2D Barcode On Manufacturer Pack"}
                        </div>
                        {pv2StationData?.data?.response?.metadata?.supply_items?.map(
                          (item: any) => {
                            return (
                              <div>
                               {item?.quantity} {item?.product_id}
                              </div>
                            );
                          }
                        )}
                        {remainingMaterials?.data?.response?.map(
                          (item: any) => {
                            return (
                              <div>
                                {item?.quantity} {item?.product_description}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {isAutomode ? (
                <div className="col-sm-6 col-md-6 col-lg-4">
                  <div className="blue-card-panel">
                    <div className="b-card-head">
                      <h5>
                        Passed Imaging Check:{" "}
                        {
                          pv2StationData?.data?.response?.metadata
                            ?.image_message
                        }
                      </h5>
                    </div>
                    <div className="b-card-body pd20">
                      <div className="singal-img-wrap">
                        <div className="col">
                          <div className="m-img text-center">
                            <img
                              src={
                                pv2StationData?.data?.response?.metadata
                                  ?.predicted_image_path
                              }
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="blue-card-panel">
                  <div className="b-card-head">
                    <h5>Patient Label</h5>
                  </div>
                  <div className="b-card-body pd10">
                    <div className="pdfRxLabel1 label-box">
                      <div className="pdfRxLabelContainer">
                        <div style={{ fontWeight: "bolder" }}>
                          NuFACTOR Inc.
                        </div>
                        <div>1601 Old Greensboro</div>
                        <div>Kernersville, NC 27284-6855 / {pv2StationData?.data?.response?.rxLabel?.product_id === 'YCANTH' ? '800-315-0155' : '800-323-6832'}</div>

                        <div className="justifySpaceBetween mt-1 mb-1">
                          <div>
                            RX#{" "}
                            {pv2StationData?.data?.response?.rxLabel?.rx_number}
                          </div>
                          <div>
                            {pv2StationData?.data?.response?.rxLabel
                              ?.rx_fill_date &&
                              moment(
                                pv2StationData?.data?.response?.rxLabel?.rx_fill_date?.slice(
                                  0,
                                  -1
                                )
                              ).format("MM/DD/YYYY")}
                          </div>
                        </div>
                        <div className="justifySpaceBetween">
                          <div></div>
                          <div>
                            {
                              pv2StationData?.data?.response?.rxLabel
                                ?.rx_provider_name
                            }
                          </div>
                        </div>
                        <div style={{ fontWeight: "bolder" }}>
                          {
                            pv2StationData?.data?.response?.rxLabel
                              ?.patient_name
                          }
                        </div>
                        <div style={{ fontWeight: "bolder" }}>
                          {pv2StationData?.data?.response?.rxLabel?.rx_drugname}
                        </div>
                        <div style={{ fontWeight: "bolder" }}>
                          {
                            pv2StationData?.data?.response?.rxLabel
                              ?.rx_use_instructions
                          }
                        </div>
                        <div>
                          Mfg:{" "}
                          {
                            pv2StationData?.data?.response?.rxLabel
                              ?.rx_manufacturer
                          }
                        </div>
                        <div className="justifySpaceBetween">
                          <div>
                            Refills:{" "}
                            {pv2StationData?.data?.response?.rxLabel
                              ?.rx_refill_number || 0}
                          </div>
                          <div>
                            Qty:{" "}
                            {pv2StationData?.data?.response?.rxLabel?.quantity}
                          </div>
                        </div>
                        <div>
                          {
                            pv2StationData?.data?.response?.rxLabel
                              ?.rx_productdescription
                          }
                        </div>
                        <div>
                          Storage:{" "}
                          {
                            pv2StationData?.data?.response?.rxLabel
                              ?.storage_description
                          }
                        </div>
                        <div className="justifySpaceBetween">
                          <div>
                            Discard after:{" "}
                            {pv2StationData?.data?.response?.rxLabel
                              ?.discard_after &&
                              moment(
                                pv2StationData?.data?.response?.rxLabel?.discard_after?.slice(
                                  0,
                                  -1
                                )
                              ).format("MM/DD/YYYY")}
                          </div>
                          <div>
                            RPh: {pv2StationData?.data?.response?.rxLabel?.rph}
                          </div>
                        </div>
                      </div>
                      {/* <div className="barcodeImageContainerNew">
                        <img
                          className="barcodeImageNew"
                          src={
                            pv2StationData?.data?.response?.rxLabel
                              ?.base64BarCode
                          }
                          alt="barcode"
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {pv2StationData?.data?.response?.metadata
                ?.product_instructions && (
                <div className="col-sm-12">
                  <div className="note-text mr-b20">
                    <p>
                      {`Note: ${pv2StationData.data.response.metadata.product_instructions}`}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="rfm-data">
            <div className="blue-card-panel">
              <div className="b-card-head">
                <h5>Remaining Fill Materials</h5>
              </div>
              <div className="b-card-body table-responsive pd10">
                <TableContainer>
                  <Table className="table small-table cus-gray-table table-center table-bordered mr0">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Seq</TableCell>
                        <TableCell align="center">Fill Product No.</TableCell>
                        <TableCell align="center">
                          Fill Product Description
                        </TableCell>
                        <TableCell align="center">Quantity</TableCell>
                        <TableCell align="center">Lot</TableCell>
                        <TableCell align="center">Expiration</TableCell>
                        <TableCell align="center">Serial No.</TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {remainingMaterials?.data?.response?.map(
                        (item: any, index: number) => {
                          return (
                            <TableRow>
                              <TableCell align="center">{index + 1}</TableCell>
                              <TableCell align="center">
                                {item.product_id}
                              </TableCell>
                              <TableCell align="center">
                                {item.product_description}
                              </TableCell>
                              <TableCell className="text-end">
                                {item.quantity}
                              </TableCell>
                              <TableCell align="center">
                                {index === editIndex ? (
                                  <div className="t-input">
                                    <input
                                      maxLength={255}
                                      type="text"
                                      value={editedLot}
                                      name="lot"
                                      id=""
                                      onChange={textChange}
                                    />
                                  </div>
                                ) : (
                                  item.lot
                                )}
                              </TableCell>
                              <TableCell align="center">
                                {index === editIndex ? (
                                  <div
                                    className="t-input"
                                    date-date-format="mm/dd/yyyy"
                                  >
                                    <input
                                      type="date"
                                      value={editedExpiration}
                                      name="expiration"
                                      id=""
                                      onChange={textChange}
                                    />
                                  </div>
                                ) : (
                                  item.expiration &&
                                  moment(item.expiration?.slice(0, -1)).format(
                                    "MM/DD/YYYY"
                                  )
                                )}
                              </TableCell>
                              <TableCell align="center">
                                {index === editIndex ? (
                                  <div className="t-input">
                                    <input
                                      maxLength={255}
                                      type="text"
                                      value={editedSerialNumber}
                                      name="serialNumber"
                                      id=""
                                      onChange={textChange}
                                    />
                                  </div>
                                ) : (
                                  item.serial_number
                                )}
                              </TableCell>
                              <TableCell align="center">
                                {index === editIndex ? (
                                  <>
                                    <button
                                      className="action-btn"
                                      onClick={() =>
                                        editItemHandler(index, false)
                                      }
                                    >
                                      <Close fontSize="small" />
                                    </button>
                                    <button
                                      className="action-btn"
                                      onClick={updateMaterialDetailHandler}
                                    >
                                      <Save fontSize="small" />
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    className="action-btn"
                                    onClick={() => editItemHandler(index, true)}
                                  >
                                    <Edit fontSize="small" />
                                  </button>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      )}
                    </TableBody>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={3} className="text-start">
                          Total
                        </TableCell>
                        <TableCell className="text-end">
                          {pv2StationData?.data?.response?.metadata?.quantity}
                        </TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center"></TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
              </div>
            </div>
            <div className="rfm-bottom-btn d-flex align-items-center justify-content-between mr-t20">
              <button
                type="button"
                className="red-btn pd-l50 pd-r50"
                data-bs-toggle="modal"
                data-bs-target="#pfrFormModal"
                onClick={() => setPv2FailureModalFlag(true)}
              >
                Fail
              </button>
              <button
                type="button"
                className="green-btn pd-l10 pd-r10"
                data-bs-toggle="modal"
                data-bs-target="#sucmsgModal"
                onClick={passHandler}
              >
                Approve for Packout
              </button>
            </div>
          </div>
        </div>
      </div>
      <BarcodeReader
        onScan={(data: any) => {
          onScanning(data);
        }}
        onError={(error: any) => {
          console.log("barcode error it is", error);
        }}
      />
      <Pv2FailureModal
        open={pv2FailureModalFlag}
        closeModal={modalCloseHandler}
        failReasons={failReasons}
        rxId={pv2StationData?.data?.response?.metadata?.rx_id}
      />
      <SuccessModal
        open={pv2SuccessModalFlag}
        closeModal={modalCloseHandler}
        title="PV2 Processed"
        message={pv2VerificationUpdateResponse?.data?.message || ""}
      />
      <AlertModal
        open={alertModalFlag}
        closeModal={() => setAlertModalFlag(false)}
        title="Scan Top Label"
        message="Please scan the Top label to proceed with Pv2 Verification"
      />
    </section>
  );
};

export default Pv2Station;
