import { useState, useEffect, useRef } from "react";
import moment from "moment";
import "../assets/css/custom.css";
import * as html2pdf from "html2pdf.js";
import {
  JSPrintManager,
  InstalledPrinter,
  ClientPrintJob,
  PrintFilePDF,
  WSStatus,
  FileSourceType,
  DefaultPrinter,
} from "jsprintmanager";
import { toast } from "react-toastify";
import config from "../config";

interface PrintPdfInterFace {
  printRxLabelsResponse: any;
  labelType: string;
  printNow: boolean;
  setLabelPrintFlag: () => void;
}

const PrintPdfFillStation: React.FC<PrintPdfInterFace> = ({
  printRxLabelsResponse,
  labelType,
  printNow,
  setLabelPrintFlag,
}) => {
  const [labelList, setLabelList] = useState<any>();
  const printErrorRef = useRef<any>(true);

  const jspmWSStatus = () => {
    if (JSPrintManager.websocket_status == WSStatus.Open) return true;
    else if (JSPrintManager.websocket_status == WSStatus.Closed) {
      if (printErrorRef.current) {
        toast.error("JSPrintManager (JSPM) is not installed or not running!");
        printErrorRef.current = false;
      }
      setLabelPrintFlag();
      return false;
    } else if (JSPrintManager.websocket_status == WSStatus.Blocked) {
      toast.error("JSPM has blocked this website!");
      setLabelPrintFlag();
      return false;
    }
  };

  const initJspm = () => {
    if (config.apiUrl.includes("https://gateway-qa.nf-automation.com")) {
      JSPrintManager.license_url =
        "https://gateway-qa.nf-automation.com/fill/jspm"; //FIXME: THIS IS FOR QA ENV ONLY
    }
    if (config.apiUrl.includes("https://gateway.nf-automation.com")) {
      JSPrintManager.license_url =
        "https://gateway.nf-automation.com/fill/jspm"; // THIS IS FOR PROD ENV
    }
    // JSPrintManager.auto_reconnect = true;
    JSPrintManager.start();
    JSPrintManager.WS!.onStatusChanged = function () {
      if (jspmWSStatus()) {
        JSPrintManager.getPrintersInfo(0, "", 0).then((data: any) => {
          console.log("getPrintersInfo", data);
          let found = false,
            connected = false;
          for (let i = 0; i < data.length; i++) {
            if (data[i]?.default === true) {
              found = true;
              if (data[i].connected === true) {
                connected = true;
                break;
              }
            }
          }
          if (!found) {
            toast.error("Default printer is not set");
            setLabelPrintFlag();
            return;
          }
          if (!connected) {
            toast.error("Printer is not connected");
            setLabelPrintFlag();
          }
        });
      }
    };
  };

  useEffect(() => {
    if (printRxLabelsResponse && printRxLabelsResponse?.success) {
      initJspm();
      const tempData = printRxLabelsResponse?.data?.response?.map(
        (item: any) => {
          return (
            <>
              <div style={{ display: "none" }}>
                <div className="pdfTopLabel">
                  <div>{item?.topLabel?.patient_name}</div>
                  <div>{item?.topLabel?.rx_drugname}</div>
                  <div className="justifySpaceBetween">
                    <div>{item?.topLabel?.shipto_name}</div>
                    <div>{item?.topLabel?.rx_number}</div>
                  </div>
                  <div className="justifySpaceBetween">
                    <div>{item?.topLabel?.quantity}</div>
                    <div>{item?.topLabel?.packout_id}</div>
                  </div>
                  <div>{item?.topLabel?.pack_details}</div>
                  <div className="justifyCenter">
                    <img src={item?.topLabel?.base64QRCode} alt="barcode" />
                  </div>
                </div>
              </div>
              <div style={{ display: "none" }}>
                <div className="pdfRxLabel">
                  <div className="pdfRxLabelContainer">
                    <div className="boldSans">NuFACTOR Inc.</div>
                    <div className="regularArial">1601 Old Greensboro</div>
                    <div
                      className="regularArial"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {" "}
                      Kernersville, NC 27284-6855 / {item.rxLabel?.product_id === 'YCANTH' ? '800-315-0155' : '800-323-6832'}
                    </div>

                    <div className="justifySpaceBetween">
                      <div className="regularArial">
                        RX# {item?.rxLabel?.rx_number}
                      </div>
                      <div className="regularArial">
                        {item?.rxLabel?.rx_fill_date &&
                          moment(
                            item?.rxLabel?.rx_fill_date?.slice(0, -1)
                          ).format("MM/DD/YYYY")}
                      </div>
                    </div>
                    <div className="justifySpaceBetween">
                      <div></div>
                      <div className="regularArial">
                        {item?.rxLabel?.rx_provider_name
                          ? item?.rxLabel?.rx_provider_name
                          : " "}
                      </div>
                    </div>
                    <div className="boldSans">
                      {item?.rxLabel?.patient_name}
                    </div>
                    <div className="boldSans">{item?.rxLabel?.rx_drugname}</div>
                    <div className="boldSans">
                      {item?.rxLabel?.rx_use_instructions}
                    </div>
                    <div className="regurlarSans">
                      Mfg: {item?.rxLabel?.rx_manufacturer}
                    </div>
                    <div className="justifySpaceBetween">
                      <div className="regularArial">
                        Refills: {item?.rxLabel?.rx_refill_number}
                      </div>
                      <div className="regularArial">
                        Qty: {item?.rxLabel?.quantity}
                      </div>
                    </div>
                    <div className="regularArial">
                      {item?.rxLabel?.rx_productdescription}
                    </div>
                    <div className="regularArial">
                      Storage: {item?.rxLabel?.storage_description}
                    </div>
                    <div className="justifySpaceBetween">
                      <div className="regularArial">
                        Discard after:{" "}
                        {item?.rxLabel?.discard_after &&
                          moment(
                            item?.rxLabel?.discard_after?.slice(0, -1)
                          ).format("MM/DD/YYYY")}
                      </div>
                      <div className="regularArial">
                        RPh: {item?.rxLabel?.rph}
                      </div>
                    </div>
                  </div>
                  <div className="barcodeImageContainerNew">
                    <img
                      className="barcodeImageNew"
                      src={item?.rxLabel?.base64BarCode}
                      alt="barcode"
                    />
                  </div>
                </div>
              </div>
            </>
          );
        }
      );
      setLabelList(tempData);
    }
  }, [printRxLabelsResponse, printNow]);

  useEffect(() => {
    if (labelList && labelList.length) {
      printFullPdf();
    }
  }, [labelList]);

  const printFullPdf = () => {
    let cpj = new ClientPrintJob();
    cpj.clientPrinter = new DefaultPrinter();
    var element = document.querySelector(".pdfParent");
    html2pdf(element, {
      margin: 0,
      filename: "myfile.pdf",
      image: { type: "jpeg", quality: 2 },
      html2canvas: { scale: 4, logging: true, dpi: 192, letterRendering: true },
      jsPDF: { unit: "pt", format: "a4", orientation: "portrait" },
      return: true,
    })
      .get("pdf")
      .then(function (pdf: any) {
        // pdf.autoPrint();
        // window.open(pdf.output("bloburl"), "_blank");
        let base = pdf.output("blob");
        var myImageFile = new PrintFilePDF(
          base,
          FileSourceType.BLOB,
          "label.pdf",
          1
        );
        myImageFile.printAsGrayscale = true;
        cpj.files.push(myImageFile);
        cpj.sendToClient();
      });
  };

  return (
    <div style={{ display: "none" }}>
      <div className="pdfParent">
        {Array.from(
          Array(
            printRxLabelsResponse?.data?.response?.[0]?.rxLabel
              ?.rx_item_bag_label === true
              ? 1
              : printRxLabelsResponse?.data?.response?.[0]?.rxLabel?.quantity
          ),
          (arrItem, arrIndex) => {
            return (
              <>
                {arrIndex == 0 &&
                  printRxLabelsResponse?.data?.response
                    ?.slice(0, 1)
                    ?.map((item: any, index: number) => {
                      return (
                        <>
                          <div className="pdfTopLabel2">
                            <div>{item.topLabel?.patient_name}</div>
                            <div>{item.topLabel?.rx_drugname}</div>
                            <div className="justifySpaceBetween">
                              <div>{item.topLabel?.shipto_name}</div>
                              <div>{item.topLabel?.rx_number}</div>
                            </div>
                            <div className="justifySpaceBetween">
                              <div>{item.topLabel?.quantity}</div>
                              <div>{item.topLabel?.packout_id}</div>
                            </div>
                            <div>{item.topLabel?.pack_details}</div>
                            <div className="justifyCenter">
                              <img
                                className="base64QRCodeImg"
                                src={item.topLabel?.base64QRCode}
                              />
                            </div>
                          </div>
                          <div className="html2pdf__page-break"></div>
                        </>
                      );
                    })}
                {printRxLabelsResponse?.data?.response
                  ?.slice(0, 1)
                  ?.map((item: any, index: number) => {
                    return (
                      <>
                        <div className="pdfRxLabel2">
                          <div className="boldSans font12">NuFACTOR Inc.</div>
                          <div
                            className="regularArial"
                            style={{ fontSize: "11px" }}
                          >
                            1601 Old Greensboro Kernersville, NC 27284-6855 /
                            {item.rxLabel?.product_id === 'YCANTH' ? '800-315-0155' : '800-323-6832'}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div
                              className="pdfRxLabelContainer"
                              style={{ flex: 1 }}
                            >
                              <div className="justifySpaceBetween">
                                <div className="regularArial font10">
                                  RX# {item.rxLabel?.rx_number}
                                </div>
                                <div className="regularArial font10">
                                  {item.rxLabel?.rx_fill_date &&
                                    moment(
                                      item.rxLabel?.rx_fill_date?.slice(0, -1)
                                    ).format("MM/DD/YYYY")}
                                </div>
                              </div>
                              <div className="justifySpaceBetween">
                                <div className="boldSans font12">
                                  {item.rxLabel?.patient_name}
                                </div>
                                <div className="regularArial font10">
                                  {item.rxLabel?.rx_provider_name}
                                </div>
                              </div>
                              <div className="boldSans font12">
                                {item.rxLabel?.rx_drugname}
                              </div>
                              <div className="boldSans font12">
                                {item.rxLabel?.rx_use_instructions}
                              </div>
                              <div className="regurlarSans font12">
                                Mfg: {item.rxLabel?.rx_manufacturer}
                              </div>
                              <div className="justifySpaceBetween">
                                <div className="regularArial font10">
                                  Refills: {item.rxLabel?.rx_refill_number || 0}
                                </div>
                                <div className="regularArial font10">
                                  Qty: {item.rxLabel?.quantity}
                                </div>
                              </div>
                              <div className="regularArial font10">
                                {item.rxLabel?.rx_productdescription}
                              </div>
                              <div className="regularArial font10">
                                Storage: {item.rxLabel?.storage_description}
                              </div>
                              <div className="justifySpaceBetween">
                                <div className="regularArial font10">
                                  Discard after:{" "}
                                  {item.rxLabel?.discard_after &&
                                    moment(
                                      item.rxLabel?.discard_after?.slice(0, -1)
                                    ).format("MM/DD/YYYY")}
                                </div>
                                <div className="regularArial font10">
                                  RPh: {item.rxLabel?.rph}
                                </div>
                              </div>
                            </div>
                            {/* <div
                              className="barcodeImageContainerNew"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                className="barcodeImageNew"
                                src={item.rxLabel?.base64BarCode}
                              />
                            </div> */}
                          </div>
                        </div>
                        <div className="html2pdf__page-break"></div>
                      </>
                    );
                  })}
              </>
            );
          }
        )}
      </div>
    </div>
  );
};

export default PrintPdfFillStation;
